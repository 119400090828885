<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="text-center">
          <i class="bi bi-book-fill" style="font-size:80px"></i>
          <div class="fs-1 text-uppercase mb-4">Error 404</div>
          <p>Página no encontrada</p>
          <p>Lo sentimos, pero la página solicitada no se puede encontrar en el servidor.<br>
          Puede volver a la página principal haciendo clic <router-link :to="{ name: 'Home' }">aquí</router-link></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from 'vue'
export default {
  name: '404',
  setup () {
    onMounted(() => {
      document.body.style.backgroundColor = '#5775ba'
    })
  }
}
</script>
